import * as React from 'react';
import Moment from 'react-moment';
import Pagination from 'react-js-pagination';
import { apiCall } from '../../helpers/axios';
import { IBroadcast, IPage } from '../../types/page';
import CenterLoader from '../Loader/Loader2';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useActivePage } from '../../helpers/utils';

const Broadcast: React.FC = () => {
  const [broadcasts, setBroadcasts] = React.useState([] as IBroadcast[]);
  const [metadata, setMetadata] = React.useState({ limit: 20, page: 1, totalCount: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(true);
  const navigate = useNavigate();
  const activePage = useActivePage();

  const getBroadcasts = async (page = 1, limit = 20) => {
    try {
      setIsLoadingMore(true);
      const query = new URLSearchParams({
        limit: String(limit),
        page: String(page),
        pageId: (activePage as IPage).fbId,
      });

      const { data: { broadcasts, metadata } } = await apiCall.get(`/broadcasts?${query.toString()}`);

      setBroadcasts(broadcasts)
      setMetadata(metadata || {});
      setIsLoading(false);
      setIsLoadingMore(false);
      window.scrollTo(0, 0);
    } catch (error) {
      return [];
    }
  }

  const params = new URLSearchParams(window.location.search);
  const pageNum = Number(params.get('page'));
  React.useEffect(() => {
    getBroadcasts(pageNum || 1).then();
  }, [activePage?.fbId]);

  const handlePageChange = (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', String(page))
    navigate({ search: params.toString() });
    setMetadata({ ...metadata, page });
    getBroadcasts(page).then();
  }

  const isPending = (broadcast: IBroadcast) => broadcast.sendAt && moment(broadcast.sendAt).diff(moment(), 'milliseconds') > 0;

  const cancelSchedule = (broadcastIds: string[]) => async (e: any) => {
    e.preventDefault();
    let isAll = !broadcastIds.length;
    if (isAll) {
      broadcastIds = broadcasts.filter(isPending).map(b => b._id);
    }
    const message = `Cancel and delete ${isAll ? broadcastIds.length : 'this'} scheduled broadcast(s)?`;
    if (!window.confirm(message)) return;

    setBroadcasts(broadcasts.filter(b => !broadcastIds.includes(b._id)));
    try {
      await apiCall.post(`/broadcasts/cancel`, {
        pageId: activePage?.fbId,
        broadcastIds,
      });
      toast.success('Cancelled and deleted successfully.');
    } catch (error) {
      setBroadcasts(broadcasts);
      toast.error('Error occurred.');
    }
  }

  const BroadcastPage = () => {
    return (
      <>
        <div className="card">
          <div className="card-header border-0">
            <div className="row">
              <div className="col-6">
                <h3 className="mb-0">Broadcasts</h3>
              </div>
              <div className="col-6 text-right">
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Sent To</th>
                  <th>Delivered To</th>
                  <th>Read By</th>
                  <th>Send Time</th>
                </tr>
              </thead>
              <tbody>
                {
                  broadcasts.length ?
                    broadcasts.map((broadcast, i) => {
                      const isPendingSent = isPending(broadcast);

                      return <tr key={i}>
                        <td className="table-user">
                          {broadcast.photo && <img src={broadcast.photo} alt="" className="avatar rounded-circle mr-3" />}
                          <h4 className="font-weight-bold d-inline">
                            {broadcast.name}
                          </h4>
                        </td>
                        <td>
                          <span title={`${broadcast.successfullySent || 'N/A'}`} className="text-muted">
                            {
                              isPendingSent ? (
                                <>
                                  <span>pending</span>
                                  <a className="ml-2" onClick={cancelSchedule([broadcast._id])} href="#">cancel</a>
                                </>
                              ) : broadcast.sentToCount
                            }
                          </span>
                        </td>
                        <td>
                          <span className="text-muted">{isPendingSent ? 0 : broadcast.deliveredCount}</span>
                        </td>
                        <td>
                          <span className="text-muted">{isPendingSent ? 0 : broadcast.readCount}</span>
                        </td>
                        <td>
                          <span className="text-muted">
                            <Moment format="YYYY-MM-DD HH:mm:ss" date={broadcast.sendAt || broadcast.createdAt} />
                          </span>
                        </td>
                      </tr>
                    })
                    :
                    <tr className="table-user">
                      <td colSpan={4}><span className="text-muted">No records found</span></td>
                    </tr>
                }
              </tbody>
            </table>

            <hr className="mb-1" />

            <div className="p-3 paging">
              {!!broadcasts.filter(isPending).length && <div style={{float:'left'}}>
                <small>
                  <a onClick={cancelSchedule([])} href="#">Cancel all scheduled</a>
                </small>
              </div>}
              {isLoadingMore && <i className="fas fa-circle-notch fa-spin"></i>}
              <Pagination
                activePage={metadata.page}
                itemsCountPerPage={metadata.limit}
                totalItemsCount={metadata.totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-end"
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="container-fluid content-area mt-3">
      {
        isLoading ? <CenterLoader /> : <BroadcastPage />
      }
    </div>
  )
}

export default Broadcast;
