import * as React from "react";
import { IUser } from '../types/user';
import { IPage } from '../types/page';
import { TEST_ACCOUNT_IDS } from "../constants";

type SetValue = (value: any) => void;

export interface AppContextInterface {
  user: IUser | null;
  setUser: SetValue;
  pages: IPage[],
  setPages: SetValue,
  activePage: IPage | null,
  setActivePage: (page: IPage, updatePages?: boolean) => void,
  isTestUser: boolean,
  hasInsightsPerm: boolean,
  canViewInsights: boolean,
}

export const AppContext = React.createContext<AppContextInterface | null>(null);

const parseJson = (str: string | null) => {
  try {
    return JSON.parse(str || '');
  } catch (error) {
    console.error(error);
    return null;
  }
}

const AppContextProvider: React.FC = props => {
  const activePageStr = localStorage.getItem('__ACTIVE_PAGE__');
  const activePageObj = parseJson(activePageStr);
  const userStr = localStorage.getItem('__USER__');
  const userObj = parseJson(userStr);
  
  const scopes = userObj?.scope?.split?.(',');
  const hasInsightsPermDef = userObj && scopes.includes('pages_read_engagement') && scopes.includes('read_insights');
  const canViewInsightsDef = userObj && TEST_ACCOUNT_IDS.concat('606d6cf3c6751456475d53ef').includes(userObj.accountId);
  const isTestUserDef = userObj && TEST_ACCOUNT_IDS.includes(userObj.accountId);

  const [user, setUserInternal] = React.useState<IUser>(userObj);
  const [pages, setPages] = React.useState<IPage[]>([]);
  const [activePage, setActivePageInternal] = React.useState<IPage>(activePageObj);
  const [isTestUser, setIsTestUser] = React.useState(isTestUserDef);
  const [canViewInsights, setCanViewInsights] = React.useState(canViewInsightsDef);
  const [hasInsightsPerm, setHasInsightsPerm] = React.useState(hasInsightsPermDef);

  const setActivePage = (page: IPage, updatePages = false) => {
    localStorage.setItem('__ACTIVE_PAGE__', JSON.stringify(page));
    setActivePageInternal(page);
    if (!updatePages) return;

    const newPages = [...pages];
    const index = newPages.findIndex((p) => p.fbId === page.fbId);
    if (index === -1) return;

    newPages[index] = page;
    setPages(newPages);
  }

  const setUser = (user: IUser) => {
    localStorage.setItem('__USER__', JSON.stringify(user));
    setUserInternal(user);
    setIsTestUser(TEST_ACCOUNT_IDS.includes(user.accountId));

    const scopes = (user.scope || '').split(',');
    setHasInsightsPerm(scopes.includes('pages_read_engagement') && scopes.includes('read_insights'));
    setCanViewInsights(TEST_ACCOUNT_IDS.concat('606d6cf3c6751456475d53ef').includes(user.accountId));
  }

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        pages,
        setPages,
        activePage,
        setActivePage,
        isTestUser,
        hasInsightsPerm,
        canViewInsights,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
