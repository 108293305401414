import * as React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import { useNavigate } from 'react-router';
import { useActivePage } from '../../helpers/utils';
import { apiCall } from '../../helpers/axios';
import { IFlow } from '../../types/page';
import CenterLoader from '../Loader/Loader2';

const Flow: React.FC = () => {
  const activePage = useActivePage();
  const [flows, setFlows] = React.useState([] as IFlow[]);
  const [metadata, setMetadata] = React.useState({ limit: 20, page: 1, totalCount: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(true);
  const navigate = useNavigate();

  const deleteFlow = (flowId: string) => async () => {
    try {
      const del = window.confirm('Flow will be permanently deleted');
      if (!del) return;
      const { data: { message } } = await apiCall.delete(`/flows/${flowId}`);
      setFlows(flows.filter(flow => flow._id !== flowId));
      toast.success(message);
    } catch (error) {
      console.log(error);
      toast.error('Deleting failed')
    }
  }

  const getFlows = async (page: number = 1, limit: number = 20) => {
    try {
      setIsLoadingMore(true);
      const query = new URLSearchParams({
        limit: String(limit),
        page: String(page),
      });

      const { data: { flows, metadata } } = await apiCall.get(`/flows?${query.toString()}`);

      setFlows(flows);
      setMetadata(metadata || {});
      setIsLoading(false);
      setIsLoadingMore(false);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  }

  const params = new URLSearchParams(window.location.search);
  const pageNum = Number(params.get('page'));
  React.useEffect(() => {
    getFlows(pageNum || 1).then();
  }, [pageNum, activePage?.fbId]);

  const handlePageChange = (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', String(page))
    navigate({ search: params.toString() });
    setMetadata({ ...metadata, page });
    getFlows(page).then();
  }

  const FlowPage = () => {
    return (
      <>
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Welcome Message</h5>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2"><i className="fa fa-circle"></i></span>
                      <span className="text-nowrap">New subscriber reply</span>
                    </p>
                  </div>
                  <div className="col-auto">
                    <div className="text-dark">
                      <Link to={`/flows/welcome-message?p=${activePage?.fbId}`}>
                        <i className="fa fa-edit"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Menu</h5>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-primary mr-2"><i className="fa fa-circle"></i></span>
                      <span className="text-nowrap">Sticky Menu</span>
                    </p>
                  </div>
                  <div className="col-auto">
                    <div className="text-dark">
                      <Link to={`/flows/sticky-menu?p=${activePage?.fbId}`}>
                        <i className="fa fa-edit"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="card card-stats">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">Default Reply</h5>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-warning mr-2"><i className="fa fa-circle"></i></span>
                      <span className="text-nowrap">{activePage?.alwaysReply ? 'Always Reply' : 'Once in 12 hours'}</span>
                    </p>
                  </div>
                  <div className="col-auto">
                    <div className="text-dark">
                      <Link to={`/flows/default-reply?p=${activePage?.fbId}`}>
                        <i className="fa fa-edit"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header border-0">
            <div className="row">
              <div className="col-6">
                <h3 className="mb-0">Flows</h3>
              </div>
              <div className="col-6 text-right">
                <Link to={`/flows/create?p=${activePage?.fbId}`} className="btn btn-sm btn-neutral btn-round btn-icon">
                  <span className="btn-inner--icon"><i className="fa fa-user-edit"></i></span>
                  <span className="btn-inner--text">Create</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th colSpan={2}>Name</th>
                  <th>Created At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  flows.length ?
                    flows.map((flow, i) => {
                      return <tr key={i}>
                        <td className="table-user">
                          <Link to={`/flows/${flow._id}?p=${activePage?.fbId}`} className="font-weight-bold">
                            {flow.name}
                          </Link>
                        </td>
                        <td></td>
                        <td>
                          <span className="text-muted">
                            <Moment format="YYYY-MM-DD HH:mm:ss" date={flow.createdAt} />
                          </span>
                        </td>
                        <td className="table-actions">
                          <Link to={`/flows/${flow._id}/edit?p=${activePage?.fbId}`} className="table-action">
                            <i className="fa fa-edit"></i>
                          </Link>
                          <button onClick={deleteFlow(flow._id)} className="table-action table-action-delete" data-toggle="tooltip" data-original-title="Delete product">
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    })
                    :
                    <tr className="table-user">
                      <td colSpan={3}><span className="text-muted">No records found</span></td>
                    </tr>
                }
              </tbody>
            </table>

            <hr className="mb-1" />

            <div className="p-3 paging">
              {isLoadingMore && <i className="fas fa-circle-notch fa-spin"></i> }
              <Pagination
                activePage={metadata.page}
                itemsCountPerPage={metadata.limit}
                totalItemsCount={metadata.totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                innerClass="pagination justify-content-end"
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="container-fluid content-area mt-3">
      {
        isLoading ? <CenterLoader /> : <FlowPage />
      }
    </div>
  )
}

export default Flow;
