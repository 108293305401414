import * as React from 'react';
import { AppContext, AppContextInterface } from '../../context';
import { apiCall } from '../../helpers/axios';
import { useActivePage } from '../../helpers/utils';

const HeaderStat: React.FC = () => {
  const activePage = useActivePage();
  const { canViewInsights, hasInsightsPerm } = React.useContext(AppContext) as AppContextInterface;
  const [totalSubscribersCount, setTotalSubscribersCount] = React.useState(0);
  const [newSubscribersCount, setNewSubscribersCount] = React.useState(0);
  const [unSubscribersCount, setUnSubscribersCount] = React.useState(0);
  const [authUrl, setAuthUrl] = React.useState<string>('');

  const loadLoginUrl = async () => {
    try {
      const { data } = await apiCall.get('/auth/facebook');
      return setAuthUrl(data.url);
    } catch (err) {
      console.log(err)
    }
  }

  const getTotalSubscribersCount = async () => {
    try {
      const pageId = activePage?.fbId;
      const { data } = await apiCall.get(`/subscribers/count?pageId=${pageId}`);
      setTotalSubscribersCount(data.count);
    } catch (error) {
      console.log(error);
    }
  }

  const getNewSubscribersCount = async () => {
    try {
      const pageId = activePage?.fbId;
      const { data } = await apiCall.get(`/subscribers/count?type=new&pageId=${pageId}`);
      setNewSubscribersCount(data.count);
    } catch (error) {
      console.log(error);
    }
  }

  const getUnSubscribersCount = async () => {
    try {
      const pageId = activePage?.fbId;
      const { data } = await apiCall.get(`/subscribers/count?active=false&pageId=${pageId}`);
      setUnSubscribersCount(data.count);
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (!activePage) return;
    getTotalSubscribersCount();
    getNewSubscribersCount();
    getUnSubscribersCount();
    if (canViewInsights && !hasInsightsPerm) {
      loadLoginUrl();
    }
  }, [activePage?.fbId]);

  return (
    <div className="header bg-primary pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-6 col-7">
              <h6 className="h2 text-white d-inline-block mb-0">Page Stats</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Subscribers</h5>
                      <span className="h2 font-weight-bold mb-0">{totalSubscribersCount.toLocaleString()}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                        <i className="fa fa-users"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-blue mr-2"><i className="fa fa-circle"></i></span>
                    <span className="text-nowrap">Total Subscribers</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">New Subscribers</h5>
                      <span className="h2 font-weight-bold mb-0">{newSubscribersCount.toLocaleString()}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                        <i className="fa fa-user-plus"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-green mr-2"><i className="fa fa-circle"></i></span>
                    <span className="text-nowrap">Since today</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">UnSubscribers</h5>
                      <span className="h2 font-weight-bold mb-0">{unSubscribersCount.toLocaleString()}</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="fa fa-user-minus"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-red mr-2"><i className="fa fa-circle"></i></span>
                    <span className="text-nowrap">Total Unsubscribers</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {canViewInsights && hasInsightsPerm && (
            <p className='alert alert-success text-dark mt--2 py-1'>
              <span>New widget available to view page insights, reauthenticate with facebook to activate. </span>
              <a href={authUrl}>Click here</a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeaderStat;
